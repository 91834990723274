/* Individual item */

.bm-menu-wrap{
  width:75vw !important;
}
.bm-menu{
  padding-top:15vh !important;
  padding-left:9vh !important;
  text-align: left;
}
.bm-item {
  font-family: 'Roboto';
  font-weight: 500;
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #242424;
    transition: color 0.2s;
  }

  .bm-item.menu-item {
    font-family: 'Roboto';
    font-weight: 500;
  font-size:4vh;
  padding-bottom:1vh;

  }

  
  /* Change color on hover */
  .bm-item:hover {
    color: #242424;
  }
  
  /* The rest copied directly from react-burger-menu docs */
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 45px;
    height: 22px;
   /* right: 22px;
    top: 40px;
*/
right: 0px;
top:0px;
    display:grid;
  grid-area: 1 / 3 / span 1 / span 1; 
 /* place-self:center;
  object-fit: cover;
 */
  align-self: center;
   justify-self: center;

  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    border-radius: 25px;
    
    
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px !important;
    width: 24px !important;
    margin-right:25px;
    margin-top:15px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: black;
    height: 34px !important;
    width:5px !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(255,255,255,0.9);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 544px) {

    .bm-item.menu-item {
     
    font-size:  4vw;
    padding-bottom:1vw;
  
    }

    .bm-menu-wrap{
      width:35vw !important;
    }

  }

  @media (min-width: 768px) and (min-height: 420px){ /* keine langen Smartphones quer...**/

    .bm-menu-wrap{
      width:55vw !important;
    }

    .bm-cross-button {
      height: 75px !important;
      width: 75px !important;
     margin-right:75px;
     margin-top:25px;
    }

    .bm-cross {
      height: 64px !important;
      width:10px !important;
    }

  }