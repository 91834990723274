input{
  border-color:grey;
}
.App {
  text-align: center;
}

.FoerdererContainer{
  display: flex; 
  flex-direction: row;
  justify-content: center;

align-content: center;

flex-wrap: wrap;
margin:2vh;
}

.FoerdererItem{
 min-width: 0;
margin: 10px;
}

.FoerdererItem>img{  
  max-width: 20vw; 
max-height: 80px; 
}


.NoSupport{

 

}
.NoSupportButton{

  height:8vh;
  margin: 4vh;
  pointer-events:all;
  z-index: 20;
  cursor: pointer;
}


.NoSupportFullImage {
  object-fit:contain;
  width:100%;
  height:100%;
 /* place-self:center;*/
  z-index: 1;
}



.NoSupportText{
  padding:5vh;
  position: absolute;
  top:0;
  margin:10vh;
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
  color:black;
  background-color: rgba(255,255,255,0.7);
  z-index: 5;
  font-size: 3vh;
  font-weight:600;
}

.NoSupportLinks{
  font-size: 2.5vh;
  font-weight:500;
}


p{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.p5Canvas{
  object-fit: cover;
}

.GridcontainerDommuseum, .GridcontainerStart {
  position:absolute;
  display:grid;
  grid-template-columns: 30vw 40vw 30vw;
  grid-template-rows: [headstart] 20vh [headend bodystart] 60vh [bodyend bottomstart] 20vh [bottomend];

  grid-template-areas: 
    "header header header"
    "screen  screen  screen"
    "footer footer footer";
    align-items:center;

    overflow: hidden;
}

.ScreenWindow{
  height: 100%;
  width:100%;
  position:relative;
  grid-area: screen;
  display:grid;
  grid-template-columns: 15% 70% 15%;
    grid-template-rows: [headstart] 15% [headend bodystart] 70% [bodyend bottomstart] 15% [bottomend];
    grid-template-areas: 
                            "screen screen screen"
                            "screen  screen  screen"
                            "screen screen screen";
    align-items:center;
    overflow: hidden;
  pointer-events: none;
  z-index:20;
}

.ScrollToElement{
  position: absolute;
/* top:-15vh;
*/
}

.Screen{
  height: 100%;
  width:100%;
  position:relative;
  grid-area: screen;
  
  display:grid;
  grid-template-columns: 15% 70% 15%;
    grid-template-rows: [headstart] 15% [headend bodystart] 70% [bodyend bottomstart] 15% [bottomend];
    grid-template-areas: 
                            "screen screen screen"
                            "screen  screen  screen"
                            "screen screen screen";
    align-items:center;
    overflow: hidden;
  pointer-events: none;
  z-index:20;
}

.Impressum{
  font-family: 'Roboto';
  font-style: normal;
  font-size:2vh;
  height: 100%;
  width:100%;
  position:relative;
  grid-area: screen;
  color: white !important;
  text-align:left;
  background-color: black;
  z-index:20;
  overflow:auto;
 opacity: 1;

 -webkit-transition: all ease-out .6s;
    transition: all ease-out .6s;
}

.Hide.Impressum{
visibility: hidden;
opacity: 0;
}
.Impressum>p{
  margin:2% 10%;
}

.ImpressumButton{
  margin:5% 10% 5% auto;
  text-align:center;
  width:5%;
  height: 5%;
  color:black;
  background-color:white;
}

.Impressum>a,.Impressum>a:link,.Impressum>a:hover,.Impressum>a:active,.Impressum>a:visited{
  color:white;
}
:any-link {
  color: white;
}

.ShareOverlay{
  grid-area: screen;
  justify-self: center;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  align-content:center;
  height:100%;
  width:100%;
  margin:50%;
  position:relative;
  pointer-events: all;
  background-color: rgba(255,255,255,0.9);
  z-index: 30;


  -webkit-transition: all ease .4s;
  transition: all ease .4s;
}

.Hide.ShareOverlay{
  background-color: rgba(255,255,255,0);
  pointer-events: none;

}



.FlexButton>svg{
height: auto !important;
width:15vw !important;
}

.ShareButton{
  align-self: flex-start;
  width: 42px; 
  height: 42px; 
  margin: 2%;  
  opacity:1;
  pointer-events: all;
  cursor: pointer;
  transform:scale(1);
  -webkit-transition: all ease .4s;
    transition: all ease .4s;
}

.Hide.ShareButton{
transform:scale(0);
pointer-events: none;
}

.GridcontainerDommuseum, .GridcontainerStart {
  -webkit-transition: all ease-out .4s;
    transition: all ease-out .4s;
}
.LoadingScreen{
  display:grid;
  grid-area: screen; 
  align-self: center;
   justify-self: center;
   text-align: center;
  background-color:rgba(255,255,255,0.7);
   z-index: 60;
   width:100%;
    height: 100%;
}

.CountNumber{
  font-family:'Roboto Slab';
  font-size:18vw;
}

.CounterText,.AusstellungProgrammMain {
  overflow:hidden;
  position: relative;
  padding-top:10vh;
  grid-area: screen;
  object-fit: contain;
  width:100%;
  height:100%;
  /*place-self:center;*/
  justify-self: center;
      align-self: center;
  align-items:center;
  z-index: 1;


  font-family: 'Fira Mono', monospace;
  font-size:9vw;
  font-weight: 500;

  background-color:rgb(0, 0, 0);
  color:white;
  /*overflow:auto;
*/
  opacity: 1;
  -webkit-transition:opacity 14500ms ease-out;
  transition:opacity 14500ms ease-out;
  pointer-events: none;

}
.AusstellungProgrammMain {
  color:black;
  background-color: white;
  overflow:hidden;
  opacity: 0.79;
}
.fade.CounterText, .fade.AusstellungProgrammMain{
  overflow:hidden;
  opacity: 0.79;
}

.WannText {
  position: relative;
  align-items: center ;
  font-size:2vh;
  overflow:auto;
}

.FrageText{
  position: relative;
  overflow:auto;
}



.AusstellungInfo,.AusstellungProgramm, .AussstellungInfoDetails,.AusstellungInfoWann,.FoerdererLogos{
  position:relative;
  z-index: 1;
  overflow:auto;
  font-family: 'Fira Mono', monospace;
  font-size:3vw;
  font-weight: 500;
  color:white;
  z-index: 90;

}

.AusstellungProgramm{
  margin:0vh 2vh;
  padding:0px;
  color:black;
  text-align: left;
}
.AusstellungProgramm a{
  pointer-events: all;
  color:blue;
}
.AusstellungProgramm p{
  font-size: 2.2vh;
}

.AusstellungProgramm h4{
  margin-block-start: 4vh;
  font-size: 2.5vh;
}

.AusstellungProgramm h3{
  margin-block-start: 10vh;
  font-size: 3vh;
}

.AusstellungInfo{
  padding-top:7vh;
}

.AussstellungInfoDetails h3, .FoerdererLogos h3{
  font-size:5vw;
  margin-block-start: 10vh;
}

.AussstellungInfoDetails h4, .FoerdererLogos h4{
  font-size:4vw;
}

.FoerdererLogos h4{
  font-size: 3vw;
  }

.AussstellungInfoDetails p, .AusstellungInfo p,.AusstellungInfoWann p, .FoerdererLogos p{
  font-size:4vw;
  text-align: left;
  margin-left:4vw;
  margin-right: 4vw;
}

.AusstellungInfo h1,.AusstellungProgramm h1, .AusstellungInfoWann h1{
  font-family:'Roboto Slab';
  font-size:8vw;
  text-align:center;
  font-weight: 700;

  margin-left:4vw;
  margin-right: 4vw;
}
.AusstellungInfoWann h1{
margin:4vw 6vw;
}
.AusstellungInfoWann>ul{
  list-style: none;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left:1vh;
  margin-right: 1vh;
  padding:0;
}

.LogoDomWann{
  width:50%;
}

.AusstellungInfoWann h1{
  font-size:11vw;
}



/* BUTTONS **/
.Btn{
  cursor: pointer;
}


.RightBtnRow{
  display: flex; 
  flex-direction:row;
  align-items: center;
flex:2 0;
  width:100%;
  height: 100%;
  margin-left: 0;
}


.FlexButton{
  flex:2 0;
  width:15vw;
  margin:3vw;

  pointer-events:all;
  z-index: 20;

}

.InfoButton,.LandesmuseumButton,.DommuseumButton{
  flex:2 0;
  height:11vw;
  margin-right: 10px;
  pointer-events:all;
  z-index: 20;
  cursor: pointer;
}

.PfeilButtonLinks,.PfeilButtonRechts{
  flex:2 0;
  height:5vw;
  margin-right: 10px;
  pointer-events:all;
  z-index: 20;
  cursor: pointer;

}

.PfeilButtonRechts{
transform: rotate(180deg);
cursor: pointer;
}

polyline.arrowScroll{
  fill:none;
  stroke:#706F6F;
  stroke-width:1;
  stroke-linecap:round;
  transition: stroke .4s ease-in-out; 
}

.UpButton,.DownButton{
  grid-area: screen;
  align-self: end;
  justify-self: right;
  height:10vw;
width:10vw;
margin-right:2vw;
margin-bottom:1vw;

  pointer-events:all;
  z-index: 20;

  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
  cursor: pointer;
}



.DownButton{
/*margin-bottom:10px;
*/
}


.NextBtn>svg, .PrvBtn>svg,.ScrollDownButton>svg, .ScrollUpButton>svg{
  width:100%;
  height: 100%;
}

.FooterBtn {
  display: flex; 
  flex-direction: row;
  grid-area: footer;
  padding:20px;
  justify-content: space-evenly;
  align-items: center ;
  z-index: 22;
  cursor: pointer;
}




.FlexLogo{
  flex:8 0;
  width:15vw;
  margin:3vw;
  z-index: 15;
  cursor: pointer;
  object-fit: inherit;
  pointer-events: all;
}

.MobileMenu {
  display:grid;
  grid-area: 1 / 3 / span 1 / span 1; 
  align-self: center;
   justify-self: center;
   width:40%;
   z-index: 30;
}

.Anzeige{
  /*flex:1 0;*/
    width:90vw;
  height: 8vh;
  margin:3vw;
  background-color:black;
  pointer-events:none;
  z-index: 5;
  white-space: nowrap;
}


.AnzeigeText,.Anzeige h1{
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 700;
  color:white;

}
.AnzeigeText{
  
  font-size:4.5vw;
  flex:1 0;
  width:85vw;

  text-align: left;
  line-height: 3.75vh;
  pointer-events:none;
  z-index: 5;
}

.Anzeige h1{
  font-size: 5vw;
}

.InfoButtonText,.AnkuendigungText,.InfoText{
  font-family: 'Roboto';
  font-style: normal;
  text-align: left;
  color:white;
  pointer-events:none;
  z-index: 5;
}

.InfoButtonText{
  flex:10 1;
  text-align: right;
  font-size:2.3vh
}

.AnkuendigungText{
  font-size:3.9vw;
  flex:1 1;
  width:85vw;
}

.InfoText{
  display:grid;
  grid-area: screen;
  margin:3vw;
  line-height: 5.5vw;
  font-size:4vw;
}

.ZweiOrteText, .ZweiOrteHeader{
  grid-area: screen;
  font-family: 'Roboto';
  font-style: normal;
  font-size:3.9vw;
  width:85vw;
  color:black;
  text-align:center;
  font-weight: 400;
  margin-top:20vh;
}

.InfoRow,.InfoRowStart {
  flex:1;
  width:100vw;
  display: flex; 
  flex-direction: row;
  grid-area: screen;
  align-self: end;
  justify-self: right;
  margin-bottom:10px;
  pointer-events:none;
  z-index: 5;
  }

.InfoRowStart{
    margin-top:4vh;
  }

.InfoText h1{
  font-weight: 700;
  font-size:7vw;
  line-height: 7.5vw;
  text-align: center;
  margin-bottom:7vw;
  pointer-events:none;
  z-index: 5;
}

.ZweiOrteText{
  margin-top:0vh;
}

.ZweiOrteHeader h1{
  background-color: black;
  color:white;
  font-size:6vw;
  text-align:center;
  font-weight: 500;
}

.SwipeArea,.Animation,.CenterImage,.ScreenImage,.AnimationBackground  {
  position:absolute;
  grid-area: screen;
  object-fit: contain;
  width:100%;
  height:100%;
  z-index: 19;
}

.AnimationBackground{
  grid-area: 2/ 2/ span 1 / span 1; 
  background: white;
}

.Animation {
  z-index: 2;
}

.CenterImage {
  width:80%;
  height:80%;
  /*place-self:center;*/
  justify-self: center;
      align-self: center;
  z-index: 1;
}

.ScreenImage {
  width:101%; /* 90**/
  height:101%;
  /*place-self:center;*/
  justify-self: center;
      align-self: center;
  z-index: 1;
}

.ScreenImage>div{
width:100%;
height: 100%;
}

canvas{
  width:100% !important;
  height: 100% !important;
}

.FullImage {
  grid-area: 1 / 1 / span 3 / span 3; 
  object-fit: cover;
  width:100%;
  height:100%;
 /* place-self:center;*/
 justify-self: center;
      align-self: center;
  z-index: 1;
}

.topBackgroundImg{
  grid-area: header;
 object-fit: cover;
  width:100%;
  height:100%;
  z-index: 21;
}
.bottomBackgroundImg{
  grid-area: footer;
  object-fit: cover;
  width:100%;
  height:100%;
  z-index: 21;
}

.LogoZeitenwendeMobil, .LogoZeitenwendeStart{
  grid-area: header;
  object-fit:fill;
  width:50vw; 
  z-index: 22;
}

.LogoZeitenwendeStart{
  justify-self: center;
}

.LogoZeitenwendeMobil{
  margin-left:15vw;
  width:45vw;
  cursor: pointer;
}

.Hashtag, .HashtagStart {
  position: absolute;
  grid-area: screen;
  font-family: 'Fira Mono', monospace;
  font-size:5vw;
  font-weight: 500;
  background-color: black;
  color:white;
  
  object-fit: contain;
  width:50vw;
  height:14vw;
  /*place-self:center;
*/
justify-self: center;
      align-self: center;
  
  animation-name: Hashtag-Scale;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-transition: all ease-out 1s;
  transition: all ease-out 1s;
  
  /* antialiasing ?*/
  box-shadow: 1px 1px 1px transparent;
  z-index:20;
  pointer-events: all;
}

.HashtagStart {
  width:55vw;
  height:16vw;
 /* place-self:baseline;*/
 
      align-self: baseline;
  justify-self: center;
  margin-top:4vh;
}

.HashtagText,.HashtagTextStart{
  margin-top: 0px;
  line-height: 14vw;
  font-size:9vw;
  text-align: center;

}

.HashtagTextStart{
line-height: 16vw;
}




/* ###### ANIMATIONEN **/

.Show.GridcontainerStart, .Show.GridcontainerDommuseum{
  visibility: visible;
  opacity:1;
}
 .Hide.GridcontainerDommuseum{
  visibility:hidden;
  opacity: 1;
}

.Hide.GridcontainerStart{
  -webkit-transform: translate3d(-100%,0, 0);
  transform: translate3d(-100%,0, 0);
  z-index:33;

}

.HashtagOff.Hashtag,.HashtagOff.HashtagStart {
  visibility:hidden;
  overflow:auto;
  overflow-x:hidden;
  opacity: 0;

  animation-name: Hashtag-ScaleOut;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
} 
.ScreenHidden.Screen{
  visibility:hidden;
  -webkit-transform: translate3d(-100%,0, 0);
  transform: translate3d(-100%,0, 0);
  z-index:0;
}
.ScreenOutLeft.Screen,.ScreenOutRight.Screen,.ScreenInLeft.Screen,.ScreenInRight.Screen {
  visibility:visible;
  opacity: 1;
  animation-name: Screen-OutLeft;
  animation-direction: normal;
   animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-timing-function:ease-out;
} 

.ScreenOutRight.Screen {
  animation-name: Screen-OutRight;
} 
.ScreenInLeft.Screen {
  animation-name: Screen-InLeft;
} 

.ScreenInRight.Screen {
  animation-name: Screen-InRight; 
} 

/* ###### ANIMATIONEN **/
.ShowButton.UpButton , .ShowButton.DownButton {
  position: absolute;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity:1;
}

.HideButton.UpButton , .HideButton.DownButton {
  visibility:hidden;
  opacity:0;
  pointer-events: none;
  -webkit-transform: scale(2);
    transform: scale(2);
}




@keyframes Hashtag-Scale {
  from { transform: rotate(-6deg) scale(1.05);}
  to { transform: rotate(-6deg) scale(0.98);}
}


@keyframes Hashtag-ScaleOut {
  from { transform: rotate(-6deg) scale(1);}
  to { transform: rotate(-6deg) scale(1.5);}
}

@keyframes Screen-OutLeft {
  from {transform: translateX(0) ;}
  to {transform: translateX(-100%) ;}
}

@keyframes Screen-OutRight {
  from {transform: translateX(0) ; }
  to {transform: translateX(100%) ;}
}
@keyframes Screen-InLeft {
  from {transform: translateX(-100%) ;}
  to {transform: translateX(0) ;}
}

@keyframes Screen-InRight {
  from {transform: translateX(100%) ;}
  to {transform: translateX(0) ;}
}


/*************************** MEDIA QUERIES *************************/
/*******************************************************************/

@media (min-width: 544px) {

  .GridcontainerDommuseum, .GridcontainerStart {
    grid-template-columns: 0vw 85vw 15vw;
    grid-template-rows: [headstart] 20vh [headend bodystart] 60vh [bodyend bottomstart] 20vh [bottomend];
    grid-template-areas: 
                          "footer screen header"
                          "footer screen header"
                          "footer screen header";
  }

  .FlexButton>svg{
    height: auto !important;
    width:100% !important;
    }

    .RightBtnRow{
      flex-direction:column;
    }

.MobileMenu {
   justify-self: flex-end;
   align-self:flex-start;
   margin-right: 2vw;
   margin-top:2vw;
   width:5vw;
}

.LogoZeitenwendeMobil{
  grid-area: 3 / 3/ span 1 / span 1 ;
  object-fit:fill;
  width:12vw; 
  z-index: 22;
  margin-left:0px;
  margin-bottom:2vh;
  justify-self: center;
  align-self:end;
}
.LogoZeitenwendeStart{
  grid-area: 1 / 1/ span 3 / span 3 ;
  width:25vw;
  margin-top:5vh;
  margin-left:5vw;
  align-self:start;
  justify-self: center;
}


.FooterBtn {
  grid-area: 2 / 3/ span 1 / span 1 ;
  display: flex; 
  flex-direction: column;
  padding:0px;
  align-self: center;
  justify-content: space-evenly;
  /*align-items: center ;*/
  height: 50vh;

}



.FlexButton{
  flex:2 2;
  width:6vw;
  margin:3vw;
  cursor: pointer;
}

.FlexLogo{
  display: none;
  flex:8 8;
  width:13vw;
  margin:3vw;
  z-index: 15;
}

.AnzeigeText{
  font-size:5vh;
  width:80vw;
  line-height: 6.75vh;
}

.AnkuendigungText{
  font-weight: 500;
  font-size:5vh;
  flex:1 1;
  width:80vw;
}

.InfoRow {
  width:85vw;
  margin-bottom:10px;
  }

 


  .Anzeige{
    width:80vw;
    margin:3vw;
    height: 12vh;
  }
  .Anzeige h1{
    font-size: 5vh;
  }

  .UpButton,.DownButton{
    height:10vh;
    width:10vh;
  margin-right:3vh;
  margin-bottom:1vh;
  pointer-events: all;
  }

  .InfoText{
  margin:6vh;
  line-height: 6vh;
    font-weight: 500;
    font-size:4vh;
  }

  .InfoText h1{
    font-size:7vh;
    line-height: 7.5vh;
    margin-bottom:7vh;
  }

  

  .Hashtag, .HashtagStart {
    width:60vh;
    height:18vh;
    font-size:5vh;
  }

  .HashtagText, .HashtagTextStart {
    margin-top: 0px;
    line-height: 18vh;
    font-size: 12vh;
    text-align: center;
}

.HashtagStart {
  grid-area: 1 / 1/ span 3 / span 3 ;
  align-self:center;
  justify-self: center;

  margin-top: 40vh;
  width:40vh;
    height:16vh;
    font-size:5vh;
}



.HashtagTextStart {
  
  line-height: 16vh;
  font-size: 9vh;
  text-align: center;
}

.ZweiOrteText{
  width:95%;
  font-size:1.7vw;
}

.ZweiOrteHeader {
  align-self: center;
  justify-self: center;
  text-align: center;
  width:50%;
  margin-top:30vh;
  margin-left:33vw;
  margin-right: 33vw;
}

.ZweiOrteHeader h1{
  font-size:2.5vw;
 /* width:100vw;*/
 width:100%;
}
  

  p{
    /*margin-block-end: 0.5em;
  */
  }
  .InfoRowStart{
    margin-top:2vh;
  }

  .InfoButton{
    height:12vh;
    margin-right: 10px;
    z-index: 20;
    pointer-events:all;
    }

  .InfoButtonText{
    flex:12 1;
    font-size:4.5vh;
  }


  .LandesmuseumButton,.DommuseumButton{
    height:5vw;
    margin-right: 10px;
    pointer-events:all;
    z-index: 20;
  }

  .PfeilButtonLinks,.PfeilButtonRechts{
    height:2vw;
    margin-right: 10px;
    pointer-events:all;
    z-index: 20;
  }

  .CounterText,.AusstellungProgrammMain{
    font-size: 7vh;
    padding-top:10vh;
  }

  .CountNumber{
    font-size:20vh;
  }


  .AusstellungInfo,.AusstellungProgramm,.AussstellungInfoDetails p, .AusstellungInfo p,.AusstellungInfoWann p, .FoerdererLogos p{
    font-size:4vh;
  }

  .AusstellungProgramm p{
    font-size:2vh;
  }



  .AussstellungInfoDetails, .FoerdererLogos{
    font-size:4vh;
  }

 .AussstellungInfoDetails h3, .FoerdererLogos h3{
    font-size:4vh;
  }
  .AussstellungInfoDetails h4, .FoerdererLogos h4{
  font-size: 5vh;
  }

  .FoerdererLogos h4{
    font-size: 4vh;
    }

  .AusstellungInfo h1,.AusstellungProgramm h1{
    font-size:8vh;
  }

  .AusstellungInfoWann h1{
    margin:4vh 6vh;
  font-size:10vh;
  }

  .AusstellungInfo h2,.AusstellungProgramm h2{
    font-size:4.6vh;
  }

  .AusstellungInfoWann h2{
    font-size:3.5vh;
  }

  .AusstellungInfoWann>ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top:0;
    margin-bottom: 0;
    margin-left:3vh;
    margin-right: 3vh;
  }

  .LogoDomWann{
    width:90%;
  }

}

/********************* Tablets (iPad) Hochkant *******************/

@media (min-width: 768px) and (min-height: 420px){ /* keine langen Smartphones quer...**/

  .AusstellungProgramm{
    margin:0vh 4vw;
  }

  .GridcontainerDommuseum, .GridcontainerStart {
    grid-template-columns: 30vw 40vw 30vw;
    grid-template-rows: [headstart] 15vh [headend bodystart] 70vh [bodyend bottomstart] 15vh [bottomend];
  
    grid-template-areas: 
                              "header header header"
                              "screen  screen  screen"
                              "footer footer footer";
  }

  .FlexButton>svg{
    height: auto !important;
    width:100% !important;
    }

    .RightBtnRow{
      flex:4 0;
      flex-direction:row;
    }

  .LogoZeitenwendeMobil, .LogoZeitenwendeStart{
    display:grid;
    grid-area: header;
    object-fit:fill;
    width:50vh; 
    z-index: 22;
  }
  
  .LogoZeitenwendeStart{
    justify-self: center;
    margin-top:4vh;
    width:30vh; 
  }
  
  .LogoZeitenwendeMobil{
    margin-left:0;
    margin-right: 0;
    width:20vh;
  }

  .MobileMenu {
    display:grid;
    grid-area: 1 / 3 / span 1 / span 1; 
    align-self: center;
     justify-self: center;
     width:20%;
     z-index: 30;
  }

  .Hashtag, .HashtagStart {
    font-size:5vw;
    font-weight: 500;
    width:37vw;
    height:12vw;
 /*   place-self:center;*/
 justify-self: center;
      align-self: center;
  }
  
  .HashtagStart {
   align-self:self-start;

    margin-top:25vh;
    width:40vw;
    height:12vw;
  
  }

  .HashtagText,.HashtagTextStart{
    margin-top: 0px;
    line-height: 11vw;
    font-size:6vw;
    text-align: center;
  }
  .HashtagTextStart{
    font-size:7vw;
    line-height: 12vw;
  }

  .FooterBtn {
    display: flex; 
    flex-direction: row;
    grid-area: footer;
    margin:0 10vw;
    justify-content: flex-start;
    align-items: center ;
    width:80vw;
    height: 100%;
  }
  .FlexButton{
    flex:2 0;
    margin:3vw;
    margin:2vh;
  }

  .FlexLogo{
    flex:10 0;
    width:50%;
    height: 50%;
    margin:3vw;
    display:inherit;
    margin:0;
  }


.Anzeige{
  width:90vw;
  height: 9vh;
  margin:3vw;
}

.Anzeige h1{
  font-size: 4vw;
}


.AnzeigeText{
  font-weight: 700;
  font-size:3.5vw;
  flex:1 0;
  width:85vw;
  text-align: left;
  line-height: 3.75vh;
}

.AnkuendigungText{
  font-size:3vw;
  flex:1 1;
  width:85vw;
  
}

.InfoRow {  
  width:100vw;
  margin-bottom:0px;
  }


  .InfoButtonText{
    flex:10 1;
    text-align: right;
  font-size:2.8vw;
  }

  .InfoButton{
    flex:2 0;
    height:7vw;
    margin-right: 10px;
  
    z-index: 20;
  }

  .InfoText{
    font-weight: 500;
    font-size:2.8vw;
    margin:3vw;
    line-height: 3.5vw;
  }

  .InfoText h1{
    font-weight: 700;
    font-size:5vw;
    margin-bottom:7vw;
    line-height: 7.5vw;
  }

  .UpButton,.DownButton{
    height:5vh;
    width:5vh;
    margin-right: 2vh;
  }

  .AusstellungInfo,.AusstellungProgramm, .AusstellungInfoWann{
    font-size:2.8vh;
  }

  .AussstellungInfoDetails, .FoerdererLogos {
    font-size:2.8vh;
  }

  .AusstellungInfo,.AusstellungProgramm,.AussstellungInfoDetails p, .AusstellungInfo p,.AusstellungInfoWann p, .FoerdererLogos p{
    font-size:3vh;
  }

  .AusstellungInfo h1,.AusstellungProgramm h1{
    font-size:4.5vh;
  }

  .AusstellungInfo h2, .AusstellungProgramm h2, .AusstellungInfoWann h2{
    font-size:3.3vh;
  }

  .AusstellungProgramm p{
    font-size:2.5vh;
  }


 
  .AussstellungInfoDetails h3, .FoerdererLogos h3{
    font-size: 3.3vh;

  }
  .AussstellungInfoDetails h4, .FoerdererLogos h4{
    font-size: 2.8vh;

  }
  .FoerdererLogos h4{
    font-size: 2.2vh;
    }

  .AusstellungInfoWann h1{
    font-size:7.5vh;
  }
  .AusstellungInfoWann>ul{
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left:1vh;
    margin-right: 1vh;
    padding:0;
  }
  
  .LogoDomWann{
    width:100%;
  }

  .CounterText{
    font-size: 6vh;
    padding-top:10vh;
  }

  

  .CountNumber{
    font-size:11vh;
  }

  .ShareOverlay{
    height:auto;
    width:50%;
  }

  .ShareButton{
    width: 64px; 
    height: 64px; 
  }

}


/************* Tablets quer und Desktop ***********/
@media (orientation:landscape){
@media (min-width: 1024px)   { 

 

  .FoerdererContainer{
    display: flex; 
    flex-direction: row;
    justify-content: center;
  
  align-content: center;
  
  flex-wrap: wrap;
  margin:3vh;
  }
  
  .FoerdererItem{
   min-width: 0;
  margin: 20px;
  }
  
  .FoerdererItem>img{  
    max-width: 15vw; 
  max-height: 100px; 
  }

    .GridcontainerDommuseum, .GridcontainerStart {
      grid-template-columns: 20vw 60vw 20vw;
      grid-template-rows: [headstart] 20vh [headend bodystart] 60vh [bodyend bottomstart] 20vh [bottomend];
    
      grid-template-areas: 
                                "header header header"
                                "left  screen  right"
                                "leftbtm footer rightbtm";
    }

    .Screen{

      grid-template-columns: 15% 70% 15%;
      grid-template-rows: [headstart] 15% [headend bodystart] 70% [bodyend bottomstart] 15% [bottomend];
      grid-template-areas: 
                              "screen screen screen"
                              "screen  screen  screen"
                              "screen screen screen";
    }

    .FlexButton>svg{
      overflow:visible;
      height: 100% !important;
      width:auto !important;
      }

    .Hashtag, .HashtagStart {
      font-size:5vw;
      font-weight: 500;
      width:26vh;
      height:8vh;
      justify-self: center;
      align-self: center;
     /* place-self:center;*/
    }
    
    .HashtagStart {
    align-self:self-start;

      margin-top:50vh;
      width:30vh;
      height:10vh;
    
    }

    .HashtagText,.HashtagTextStart{
      margin-top: 0px;
      line-height: 8vh;
      font-size:4vh;
      text-align: center;
    }
    .HashtagTextStart{
      font-size:6vh;
      line-height: 10vh;
    }

    .ZweiOrteHeader {
      margin-top:35vh;
    }
    
    .LogoZeitenwendeStart{
      justify-self: center;
      margin-top:14vh;
      width:40vh; 
    }


    .FooterBtnDesktop {
      display: flex; 
      flex-direction: row;
      grid-area: footer;
      margin:0;
      width:33%;
      height: 33%;
      padding:20px;
      justify-content: space-evenly;
      align-items: center ;
      justify-self: center;
      z-index: 22;
      cursor: pointer;
      pointer-events: none;
    }

    .LeftBtnRow{
      display: flex; 
      flex-direction:column;
      align-items: center;
    flex:4 0;
      width:100%;
      height: 100%;
      margin-left: 15%;
    }

    .RightBtnRow{
      flex-direction:row;
    }
    .RightBtnRow{
      width:100%;
      height: 100%;
      margin-left: 0;
    }


    .RightBtnRow>.FlexText{
      flex: 2;
      margin-left:10%;
      margin:0;
    }

    /*
    .FooterBtn {
      display: flex; 
      flex-direction: row;
      grid-area: footer;
      margin:0 10vw;
      justify-content: flex-start;
      align-items: center ;
      width:80vw;
      height: 100%;
    }
*/
    .FooterBtn {
      grid-area: right ;
      display: flex; 
      flex-direction:column;
      padding:0px;
      margin:0;
      width:100%;
      align-self: center;
      
      /*align-items: center ;*/
      height: 25vh;
    }

    .RightBtnColumn, .LeftBtnColumn{
      grid-area: right ;
      display: flex; 
      flex-direction:column;
      padding:0px;
      align-self: center;
      
      /*align-items: center ;*/
      height: 25vh;
    }

    .LeftBtnColumn{
      grid-area: left ;
      justify-content: flex-start;
      height: auto;
    }




    .NextBtn,.PrvBtn{
      grid-area: 2 / 3 / span 1 / span 1; 
      justify-self: end;
      margin-right:2vh;
      width:20%;
      height: 20%;
      cursor: pointer;
      pointer-events: all;
      z-index: 22;
    }

    .PrvBtn{
      grid-area: 2 / 1 / span 1 / span 1; 
      justify-self: start;
      margin-right: 0;
      margin-left:2vh;
    }

    .ScrollDownButton, .ScrollUpButton{
      position: absolute;
      grid-area: screen;
      align-self: flex-end;
      justify-self: right;
    
      pointer-events:all;
      z-index: 20;
    
    margin-top:2vh;
    margin-bottom:2vh;
      margin-right:2vh;
      width:5%;
      height: 5%;
    
      cursor: pointer;
      -webkit-transition: all ease-out 0.3s;
      transition: all ease-out 0.3s;
      
    }
    
    .ScrollUpButton{
      align-self:flex-start;
    }



    polyline.arrow{
      fill:none; 
      stroke:#706F6F; 
      stroke-width:2.5; 
      stroke-linecap:round;
      transition: stroke .4s ease-in-out; 
    }

    
  
    .NextBtn:hover>svg polyline.arrow, .PrvBtn:hover>svg polyline.arrow, .ScrollDownButton:hover>svg polyline.arrowScroll, .ScrollUpButton:hover>svg polyline.arrowScroll, .DownButton:hover>svg polyline.arrowScroll, .UpButton:hover>svg polyline.arrowScroll{
      stroke:rgb(150,0,20);
    }



 



    .FlexText,.FlexTextLeft,.ImpressumMenu{
      font-family: 'Roboto';
      font-style: Black;
      width:50%;
      color:black;
      font-size: 2vh;
      align-self:center;
      z-index:10;
      text-align: left;
      user-select: none;
      transition: color .4s ease-in-out; 
    }
    .FlexText{
      font-style:Medium;
    }

    .FlexTextLeft,.ImpressumMenu{
      width:100%;
      margin-left: 26%;
      margin-top:5%;
      font-size: 2vh;
      text-align: left;
      font-weight: 900;
      cursor:pointer;
    }

    .ImpressumMenu{
     font-size: 1.5vh;
      font-weight: 400;
    }


    .FlexTextLeft:before {
      content: "";
      display: block;
      width: 0; 
      height: 0; 
      border-top: 0.8vh solid transparent;
      border-bottom: 0.8vh solid transparent;
      border-left: 0.8vh solid black; 
      position: absolute;
     transform: translate(-2.3vh,0.3vh);
      z-index: 20;
      transition: all .4s ease-in-out;    
  }

    .FlexTextLeft.Active, .ImpressumMenu.Active{
      color:rgb(150,0,20);
    }


    .FlexTextLeft.Active:before {
      border-left-color: rgb(150,0,20);
      transform: translate(-2.3vh,0.3vh) rotate(90deg);
      
  }

  .FlexTextLeft.Open:before {
    transform: translate(-2.3vh,0.3vh) rotate(90deg);
}

.FlexTextLeft.Active.Closed:before {
  transform: translate(-2.3vh,0.3vh) rotate(0deg);

}
    .FlexTextLeft:hover, .FlexTextLeft:hover::before,.ImpressumMenu:hover {
      border-left-color: rgb(150,0,20);
      color:rgb(150,0,20);
    }

    .FlexTextLeft>ul>li{
      font-family: 'Roboto';
      font-style: light;
      font-weight: 300;
      display:block;
      color:black;
      margin-bottom:5%;
      cursor:pointer;
      float: left;
      clear: both;
      max-height: 100%;
      opacity:1;
      transform: scaleY(1) translateY(0);
      transition: color .4s ease-in-out, opacity .1s ease, transform .1s ease, max-height .1s ease, heigth .1s ease, margin-bottom .1s ease, padding-bottom .1s ease, border .1s ease; 
      
    }

    .FlexTextLeft>ul>li:hover{
      color:rgb(150,0,20);

    }

    .FlexTextLeft.Active>ul>li.Active{
      color:rgb(150,0,20);
      border-bottom: 1.5px solid rgb(150,0,20);
       padding-bottom: 2px;

       opacity:1;
       transform: scaleY(1) translateY(0);
    }

    .FlexTextLeft>ul>li.Hidden{
      
     /* display:none;
      visibility: hidden;*/
      border:none;
      margin-bottom:0;
       padding-bottom: 0;
       max-height: 0px;
    overflow: hidden;
    opacity:0;
    transform: scaleY(1) translateY(-33%);
    height: 0%;
    }

    
    ul{
      list-style-type:none;
      margin-top:5%;
      margin-bottom:0;
      padding-left:5%;
      font-weight: 200;
      
    }

    .FlexButton{
     /* width:50%;*/
      height: 7vh;
      margin:0;
      margin-left:0;
      pointer-events:all;
      align-self:center;
      z-index: 20;
    }

    .FlexLogo{
      width:100%;
      height: 100%;
    }

    .CounterText,.AusstellungProgrammMain{
      font-size: 5vh;
      padding-top:10vh;
    }

    .CountNumber{
      font-size:11vh;
    }

    .AusstellungInfoWann>ul{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      text-align: left;
      margin-left:6vh;
      margin-right: 6vh;
    }

    .LogoDomWann{
      width:90%;
    }

    .AusstellungInfo,.AusstellungProgramm, .AusstellungInfoWann{
      font-size:1.7vh;
    }

    .AussstellungInfoDetails, .FoerdererLogos{
      font-size:1.6vh;
    }

    .AusstellungInfo p, .AusstellungInfoWann p, .AussstellungInfoDetails p, .FoerdererLogos p{
      font-size:2.2vh;
    }
    .AusstellungProgramm p{
      font-size:2.2vh;
    }
    

    .AusstellungInfo h1, .AusstellungProgramm h1{
      font-size:4vh;
    }

    .AusstellungInfoWann h1{
      font-size:8vh;
      margin:1vh;
    }

    .AusstellungInfo h2, .AusstellungProgramm h2, .AusstellungInfoWann h2{
      font-size:2.2vh;
    }

    .AusstellungInfoWann h2{
      font-size:1.8vh;

    }

    .AussstellungInfoDetails h3, .FoerdererLogos h3{
      font-size:3vh;

    }

    .AussstellungInfoDetails h4, .FoerdererLogos h4{
      font-size:2.2vh;

    }

    .FoerdererLogos h4{
      font-size: 2vh;
      }

    .LogoZeitenwendeMobil{
      margin:0;
      align-self: center;
    }

    .Anzeige{
      width:90%;
      height: 9vh;
      margin:2vh;
    }
    
    .Anzeige h1{
      font-size: 3.3vh;
    }
    
    
    .AnzeigeText{
      font-weight: 600;
      font-size:2.5vh;
      flex:1 0;
      width:85%;
      text-align: left;
      line-height: 3.75vh;
    }
    
    .AnkuendigungText{
      font-weight: 400;
      font-size:2.5vh;
      flex:1 1;
      width:85%;
      line-height: 3.75vh;
      
    }

    .InfoText{
      font-size:2vh;
      margin:5vw;
      line-height: 3.1vh;
    }
  
    .InfoText h1{
      font-size:4vh;
      line-height: 4.5vh;
      margin-bottom:3vh;
    }

    .InfoRow {  
      width:100%;
      margin-bottom:0px;
      }

  

      .InfoButton{
        flex:2 0;
        height:6vh;
        margin-right: 10px;
      
        z-index: 20;
      }

      .InfoButtonText{
        flex:12 1;
        font-size:2vh;
      }

      .ShareOverlay{
        height:auto;
        width:50%;
      }

      .ShareButton{
        width: 64px; 
        height: 64px; 
      }

  }
}
/*
@media (min-width: 2100px) { 
  #ritter > svg{
    width:80% !important;
  }

}
*/
/* Tablets Quer 
@media (min-width: 900px) and (max-height: 1100px){ 

}

@media (min-width: 1200px) {


}

*/

