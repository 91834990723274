
.ScreenOverlay,.ScreenOverlayFull {
  position:absolute;
    grid-area: screen;
    width:100%;
    height: 100%;
  
    -webkit-transition: all ease-out 1s;
    transition: all ease-out 1s;
    pointer-events:none;
    z-index: 20;
  }
  .ScreenOverlayFull{
    grid-area: 1 / 1 / span 3 / span 3; 
    
  }
  
  .ScreenOverlay.Default,.ScreenOverlayFull.Default {
    background-color:rgba(255, 255, 255, 0.7);
}
  .ScreenOverlay.Madonna {
    background-color:rgba(210, 0, 110, 0.7);
}
.ScreenOverlay.Kampf {
  background-color:rgba(185, 7, 46, 0.7);
}
.ScreenOverlay.Ferne {
  background-color:rgba(138, 173, 137, 0.7);
}
.ScreenOverlay.Messageboard {
  background-color:rgba(103, 197, 197, 0.7);
}

.ScreenOverlay.Zweiorte,.ScreenOverlayFull.Zweiorte {
  background-color:rgba(255, 255, 255, 0.7);
}
.ScreenOverlay.Info {
    background-color:rgba(0, 0, 0, 0.7);
}

  .AnimationOverlay {
    display: flex; 
    flex-direction: column;
    grid-area: screen;
  /*  padding:1vh;*/
    /*align-self: center;*/
    justify-content: space-evenly;
    align-items: center ;
    pointer-events:none;
    z-index: 19;
    height:100%;

    
  }
 

/* ###### ANIMATIONEN **/
.OverlayOff.ScreenOverlay, .OverlayOff.ScreenOverlayFull {
    visibility: hidden;
    overflow:hidden;

      -webkit-transform: translate3d(0,100%, 0);
      transform: translate3d(0,100%, 0);
  }
  
  .OverlayOn.ScreenOverlay, .OverlayOn.ScreenOverlayFull {
    visibility:visible;
    overflow:auto;
    overflow-x:hidden;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }

  .OverlayOff.ScreenOverlay::after,.OverlayOff.ScreenOverlayFull::after {
	display: none;
}