.marquee {
    height: 100%;
    width: 100%;
  
    overflow: hidden;
    position: relative;
  }
  
  .marquee div {
    display: block;
    width: 200%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  
    animation: marquee 10s linear infinite;
  }
  
  .marquee span {
    float: left;
    width: 50%;
    height: 100%;
  }
.ticker{
  z-index: 22;
}
  .ticker__element{
    z-index: 22;
  }
  
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }

  }

